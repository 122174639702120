import React from 'react';
import {Container} from "./index";
import {Breadcrumb} from "antd";
import {Icon} from "@iconify/react";
import {Link, useLocation, useNavigate} from "react-router-dom";

const items = {
    search: 'Поиск',

    cabinet: 'Кабинет',
    cart: 'Корзина',
    orders: 'Заказы',

    account: 'Кабинет',
    register: 'Регистрация',
    login: 'Вход',

    products: 'Товары'
};

export const FBreadcrumb = () => {

    const navigate = useNavigate();
    const {pathname} = useLocation();

    const pathnames = pathname.split("/").filter(Boolean);

    return(

        <Container>
            <Breadcrumb className='breadcrumb' >
                {pathnames.length ? (
                    <Breadcrumb.Item href="/" >
                        <Icon fontSize={14} icon='ion:home'/>
                    </Breadcrumb.Item>
                ) : (
                    <Icon fontSize={14} icon='ion:home'/>
                )}
                {pathnames.map((key, index) => {
                    const routeTo = `/${pathnames.slice(0, index + 1)}`;
                    const isLast = index === pathnames.length - 1;

                    const name = items[key] ? items[key] : key;

                    return isLast ? (
                        <Breadcrumb.Item key={key}>{name}</Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item key={key} href={routeTo}>{name}</Breadcrumb.Item>
                    );
                })}
            </Breadcrumb>
        </Container>
    );
}
