import React from 'react'
import './Order.less'
import {Flex} from "antd";
import dayjs from "dayjs";
import {Link} from "react-router-dom";
import {OrderPaymentStatusNames, OrderStatusNames} from "../../../constants/enums";

const Order = ({order = {}}) => {
    const {number, created, processStatus, paymentStatus,
        orderTotal = 0, orderCurrency = 'CNY',
        deliveryCost = 0,
        preliminaryOrderTotal = 0, preliminaryOrderCurrency = 'CNY',
        preliminaryDeliveryCost = 0, preliminaryDeliveryCurrency = 'RUB',
        deliveryCompany = {},
        products = []
    } = order;
    const {shortName : deliveryCompanyName} = deliveryCompany;
    // const total =orderTotal ? orderTotal : preliminaryOrderTotal;

    const getTotal = () => {
        if(!orderTotal)
            return(
                <div className='total'>{preliminaryOrderTotal.toLocaleString('zh-CN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: preliminaryOrderCurrency
                })}</div>
            )
        else
            return(
                <div className='total'>{orderTotal.toLocaleString('zh-CN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: orderCurrency
                })}</div>
            )
    }

    return(
        <div className='order'>
            <div className='header'>
                <div className='title'>
                    <div>Заказ от {dayjs(created).format('DD/MM/YYYY HH:mm')}</div>
                    <div className='order-number'>{number}</div>
                </div>
                <div className='total-container'>
                    <div>
                        <div className='status'>{OrderPaymentStatusNames[paymentStatus]}</div>
                        {getTotal()}
                    </div>
                </div>
            </div>
            <div className='content'>
                <div className='delivery-container'>
                    <div className='delivery'>
                        <div className='transport=company'>Доставка:<span className='transport-company'>{deliveryCompanyName}</span></div>
                        <div className='status'>{OrderStatusNames[processStatus]}</div>
                    </div>
                </div>

                <div className='products'>
                    {products.map(product => {
                        const {productId,  productDetail = {}} = product;
                        const {mainImgs} = productDetail;
                        const img = mainImgs[0];
                        return(
                            <Link className='product' to={`/1688/products/${productId}`} >
                                <img src={img} width='22' height='22' />
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}

export default Order;
