import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { sendMetric } from "../utils/metrics";

const LocationViewer = () => {
    const location = useLocation();

    useEffect(() => {
        sendMetric('hit', window.location.href);
    }, [location]);
}

export default LocationViewer;