import React from 'react';

import './CartFormRight.less';
import {Button, notification} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {createOrderAsync} from "./cartSlice";
import {Icon} from "@iconify/react";
import {useNavigate} from "react-router-dom";
import { sendMetric } from "../../../utils/metrics";


export const CartFormRight = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const currency = useSelector(state => state.cart.currency);
    const totalPrice = useSelector(state => state.cart.totalPrice);
    const deliveryCompanyId = useSelector(state => state.cart.currDeliveryCompany);
    const comment = useSelector(state => state.cart.comment);

    return(
        <div className="cart-right">
            <div className="cart-summary">
                <div className="cart-summary_wrapper">
                    <div className="cart-summary_footer">
                        <div className="cart-summary_total">
                            <div className="cart-summary_label total">Всего</div>
                            <div className="cart-summary_price js-cart-total-price">
                                {totalPrice.toLocaleString('zh-CN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    style: 'currency',
                                    currency: currency
                                })}
                            </div>
                        </div>
                        <Button type='primary' className="btn btn-1 btn-checkout"
                                onClick={() => {
                                    sendMetric('reachGoal','CreateOrderButtonClick');

                                    if(deliveryCompanyId) {
                                        dispatch(createOrderAsync({deliveryCompanyId, comment}))
                                            .unwrap()
                                            .then((result) => {
                                                navigate('/cabinet/order-success');
                                            })
                                            .catch((error) => {
                                                console.log(error);
                                                notification.error({
                                                    message: 'Ошибка',
                                                    description: 'Ошибка при создании заказа.',
                                                });
                                            });
                                    }
                                    else
                                        notification.warning({
                                            message: 'Внимание',
                                            description: 'Вы не выбрали транспортную компанию!',
                                            icon: <Icon icon='ant-design:exclamation-circle-outlined'  style={{ color: '#faad14' }} />,
                                        });
                                }}
                        >
                            <span>Отправить заказ</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
