import React from 'react';

import './CartMenuAction.less';
import {Icon} from "@iconify/react";
import {useDispatch, useSelector} from "react-redux";


export const CartMenuAction = () => {
    const products = useSelector(state => state.cart.items);
    const currency = useSelector(state => state.cart.currency);
    const totalPrice = useSelector(state => state.cart.totalPrice);

    const prodCnt = products.length;
    const allCnt = products.reduce((cnt, product) => {
            const {orderedSkus = []} = product;
            const orderedCnt = orderedSkus.reduce((acc, sku) => {
                const {orderedCount = 0} = sku;
                return acc + orderedCount
            }, 0);
            return cnt + orderedCnt;
        }
        , 0);



    return(
        <>
            <div className='icon'>
                <Icon fontSize={32} icon='ph:bag-light'/>
                <div className='number'>{prodCnt > 0 ? `${prodCnt}` : 0 }</div>
            </div>
            <div className='total-price'>
                {totalPrice.toLocaleString('zh-CN', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: currency
                })}
            </div>
        </>
    )
}
