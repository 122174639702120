import { Layout, Card, Divider, Flex } from 'antd';
import './PlatformDescription.less'
const { Header, Content, } = Layout;

const PlatformDescription = () => {
    return (
        <Layout className='platform-description'>
                <Header className='platform-description-header'>
                    <h1>Поиск и Заказ Оптовых Товаров из Китая</h1>
                    <p>Легкий способ найти надежных поставщиков и выгодные предложения</p>
                </Header>
                <Divider />
                <Content className='platform-description-content'>
                    <Flex justify='center' >
                        <Card title="Широкий Ассортимент" bordered={false} className='platform-description-card'>
                            <p>Товары для любого бизнеса, от электроники до текстиля.</p>
                        </Card>
                        <Card title="Низкие цены" bordered={false} className='platform-description-card'>
                            <p>Стоимость товаров с китайской площадки без наценок.</p>
                        </Card>
                        <Card title="Удобный Заказ" bordered={false} className='platform-description-card'>
                            <p>Простой процесс оформления заказа с поддержкой на каждом этапе.</p>
                        </Card>
                    </Flex>
                </Content>
                <Content className='platform-description-content'>
                    <h2>Начните искать товары уже сегодня!</h2>
                </Content>
        </Layout>
    )
}

export default PlatformDescription;