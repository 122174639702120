import React from 'react'

import './FSelect.less'

export const FSelect = ({items = [], deliveryCompanyId = "", onChange = (value) => {}}) => {
    return(
        <div className="select-group">
            <select className="w-100" value={deliveryCompanyId} onChange={
                (elem) => {
                    onChange(elem.target.value);
                }
            }>
                <option value="" disabled>
                    Выберите значение
                </option>
                {items.map( item => {
                    const{id,caption} = item;
                    return(
                        <option value={id}>{caption}</option>
                    )
                })}
            </select>
            <svg className="bold-angle-down" width="20" height="20" viewBox="0 0 20 20" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 8L10 13L15 8H5Z" fill="currentColor"></path>
            </svg>
        </div>
    )
}
