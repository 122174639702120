import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Empty, Result, Skeleton, Space, Typography} from 'antd';
import { Link } from 'react-router-dom';
import './CreateOrderResult.less';
import {createOrderStatus} from "../../../constants/enums";
import {clearOrderStatus} from "./cartSlice";


const CreateOrderSuccess = () => {
    const dispatch = useDispatch();
    const orderStatus = useSelector(state => state.cart.createOrderStatus);
    const {number = ''} = useSelector(state => state.cart.createOrder);

    useEffect(() => {
        // Сбрасываем флаг при размонтировании компонента
        return () => {
            dispatch(clearOrderStatus());
        };
    }, [dispatch]);

    if(orderStatus === createOrderStatus.CREATE_ORDER_SUCCESS){
        return (
            <Space direction='vertical' className='create-order-result'>
                <Result status="success" title={`Заказ под номером ${number} зарегистрирован успешно.`}/>
                <Link to={'/cabinet/orders'}>Все заказы</Link>
            </Space>
        )
    } else if (orderStatus === createOrderStatus.CREATE_ORDER_FAIL){
        return (
            <Result status="error" title='Ошибка создания заказа'/>
        )
    }
    else {
        return (
            <Empty
                description={
                    <Typography.Text>
                        Нет новых заказов
                    </Typography.Text>
                }
            />
        )
    }
}
export default CreateOrderSuccess;
