import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {
    cartRequestAsync,
    clearCartRequestAsync,
    createOrderAsync,
    deliveryCompaniesAsync,
    setCurrentDeliveryCompany
} from "./cartSlice";
import {useAuth} from "../../../auth/useAuth";
import CartProduct from "./CartProduct";
import {Button, Skeleton, Select, Flex, Space, Typography, Empty, Result } from "antd";
import {Link, useNavigate} from 'react-router-dom';
import {CartFormRight} from "./CartFormRight";
import {CartFormLeft} from "./CartFormLeft";

import './Cart.less';
import {CartTable} from "./CartTable";


const { Option } = Select;


const Cart = () => {
    const dispatch = useDispatch();
    const { isCustomer = () => {}} = useAuth();
    const loading = useSelector(state => state.cart.loading)
    const navigate = useNavigate();

    useEffect(()=>{
        if(isCustomer()) {
            dispatch(cartRequestAsync());
            dispatch(deliveryCompaniesAsync())
        }
    },[dispatch, isCustomer]);


    const products = useSelector(state => state.cart.items);
    const currDeliveryCompany = useSelector(state => state.cart.currDeliveryCompany);

    const handleCreateOrderButtonClick = () => {
        dispatch(createOrderAsync({deliveryCompanyId: currDeliveryCompany}));
        navigate('/cabinet/cart/createOrderResult');
    }

    const productsRender = () => {
        if(products.length > 0) {
            return (
                <div className='cart-page'>
                    <CartTable />
                    <div className='cart-btn-wrapper'>
                        <Link className='btn btn-2' to='/' >
                            Продолжить покупки
                        </Link>
                        <Button type='primary' className='btn btn-1' onClick={() => {
                            dispatch(clearCartRequestAsync())
                        }}>
                            Очистить
                        </Button>
                    </div>
                    <div className='cart-form-wrapper'>
                        <CartFormLeft />
                        <CartFormRight />
                    </div>
                </div>
            )
        } else {
            return (
                <Empty
                    description={
                        <Typography.Text>
                            Нет товаров в корзине
                        </Typography.Text>
                  }
                />
            )
        }
    }

    if (isCustomer())
        if (loading)
            return (<Skeleton active/>)
        else
            return (
                productsRender()
            );
}

export default Cart;
