import React from 'react';

import './CartFormLeft.less'
import {FSelect} from "../../../components/FSelect";
import {useDispatch, useSelector} from "react-redux";
import {setComment, setCurrentDeliveryCompany} from "./cartSlice";

export const CartFormLeft = () => {
    const dispatch = useDispatch();

    const comment = useSelector(state => state.cart.comment);
    const deliveryCompanies = useSelector(state => state.cart.deliveryCompanies);
    const deliveryCompanyId = useSelector(state => state.cart.currDeliveryCompany);
    const comps = deliveryCompanies.map(item => {
        const {id, shortName} = item;
        return {id, caption: shortName}
    });

    return(
        <div className="cart-left">
            <div className="cart-form_group note-and-shipping">
                <div className='shipping'>
                    <div className='title'>
                        Транспортная компания
                    </div>
                    <div className='shipping-form d-flex'>
                        <div className='form-group'>
                            <FSelect items={comps} deliveryCompanyId={deliveryCompanyId}  onChange={(deliveryCompanyId) => {dispatch(setCurrentDeliveryCompany(deliveryCompanyId))}} />
                        </div>
                    </div>
                </div>
                <div className="cart-note">
                    <div className="cart-note_title d-flex justify-content-between align-items-center">
                        <span>Комментарий к заказу</span>
                    </div>
                    <textarea className="note"
                              spellCheck="false"
                              maxLength="1200"
                              value={comment}
                              onChange={(elem) => {dispatch( setComment(elem.target.value))}}
                    />
                </div>
            </div>
        </div>
    )
}
