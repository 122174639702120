import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import '../../../constants/enums';
import {
    addCartItem,
    cart,
    createOrder,
    getDeliveryCompanies,
    deleteCartItem,
    deleteCartItemProp,
    clearCart
} from "../../../services/apiService";
import {createOrderStatus} from "../../../constants/enums";
import { useNavigate } from 'react-router-dom';

function getTotalPrice(items) {
    return items.reduce((accum, value) => {
        const {orderedSkus = []} = value;
        return orderedSkus.reduce((skuAccum, sku) => {
            const {salePrice = 0, orderedCount = 0} = sku;
            return salePrice * orderedCount + skuAccum;
        }, 0) + accum;
    }, 0);
}

export const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        loading: false,
        addItemStatus: 'idle',
        addItemError: null,
        itemAdding: false,
        items: [],
        totalPrice: 0,
        currency: 'CNY',
        deliveryCompanies: [],
        currDeliveryCompany: "",
        comment: undefined,
        createOrderStatus: createOrderStatus.NONE,
        createOrder: {}
    },
    reducers: {
        setCurrentDeliveryCompany: (state, {payload}) => {
            state.currDeliveryCompany = payload;
        },
        setComment: (state, {payload}) => {
            state.comment = payload;
        },
        clearOrderStatus: (state, {payload}) => {
            state.createOrderStatus = createOrderStatus.NONE;
            createOrder: {};
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(addCartItemRequestAsync.pending, (state, action) => {
                state.itemAdding = true;
                state.addItemStatus = 'loading';
                state.addItemError = null;
                // state.totalPrice = 0;
            })
            .addCase(addCartItemRequestAsync.fulfilled, (state, action) => {
                const {items = []} = action.payload;
                state.addItemStatus = 'succeeded';
                state.items = items;
                state.totalPrice = getTotalPrice(items);
            })
            .addCase(addCartItemRequestAsync.rejected, (state, action) => {
                state.addItemStatus = 'failed';
                state.addItemError = action.payload || 'Request failed';
                state.totalPrice = 0;
            })
            .addCase(deleteCartItemRequestAsync.fulfilled, (state, action) => {
                const {items = []} = action.payload;
                state.items = items;
                state.totalPrice = getTotalPrice(items);
            })
            .addCase(deleteCartItemPropRequestAsync.fulfilled, (state, action) => {
                const {items = []} = action.payload;
                state.items = items;
                state.totalPrice = getTotalPrice(items);
            })
            .addCase(clearCartRequestAsync.fulfilled, (state, action) => {
                const {items = []} = action.payload;
                state.items = items;
                state.totalPrice = 0;
            })
            .addCase(deliveryCompaniesAsync.fulfilled, (state, action) => {
                const {content = []} = action.payload;
                state.deliveryCompanies = content;
            })
            .addCase(cartRequestAsync.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(cartRequestAsync.fulfilled, (state, action) => {
                const {items = []} = action.payload;
                state.loading = false;
                state.items = items;
                state.totalPrice = getTotalPrice(items);
            })
            .addCase(createOrderAsync.pending, (state, action) => {
                state.loading = true;
                state.createdOrder = {};
                state.createOrderStatus = createOrderStatus.NONE;
            })
            .addCase(createOrderAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.items = [];
                state.createOrder = action.payload;
                state.createOrderStatus = createOrderStatus.CREATE_ORDER_SUCCESS;
            })
            .addCase(createOrderAsync.rejected, (state, action) => {
                state.loading = false;
                state.createOrder = {};
                state.createOrderStatus = createOrderStatus.CREATE_ORDER_FAIL;
            })
    }
})

export const {setCurrentDeliveryCompany, setComment, clearOrderStatus} = cartSlice.actions;

export const addCartItemRequestAsync = createAsyncThunk('cart/addCartItemRequestAsync', (cartItemSku) => {
    return addCartItem(cartItemSku);
})

export const deleteCartItemRequestAsync = createAsyncThunk('cart/deleteCartItemRequestAsync', (productId) => {
    return deleteCartItem(productId);
})

export const deleteCartItemPropRequestAsync = createAsyncThunk('cart/deleteCartItemPropRequestAsync', ({productId, skuid}) => {
    return deleteCartItemProp(productId, skuid);
})

export const clearCartRequestAsync = createAsyncThunk('cart/clearCartRequestAsync', () => {
    return clearCart();
})

export const cartRequestAsync = createAsyncThunk('cart/cartRequestAsync', () => {
    return cart();
})

export const deliveryCompaniesAsync = createAsyncThunk('cart/deliveryCompaniesAsync', () => {
    return getDeliveryCompanies();
})

export const createOrderAsync = createAsyncThunk('cart/createOrderAsync', (order) => {
    return createOrder(order);
})



export default cartSlice.reducer;
