import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {useAuth} from "../../../auth/useAuth";
import {Divider, Empty, Flex, Pagination, Skeleton, Typography} from "antd";
import {ordersAsync} from "./ordersSlice";

import './OrdersPage.less'
import Order from "./Order";
import {searchProductsAsync} from "../../1688/search/search1688Slice";

const OrdersPage = () => {
    const dispatch = useDispatch();
    const { isAuthenticated = () => {}} = useAuth();
    const loading = useSelector(state => state.orders.loading);
    const orders = useSelector(state => state.orders.orders);
    const pagination = useSelector(state => state.orders.pagination);
    const sorting = useSelector(state => state.orders.sorting);

    useEffect(()=>{
        if(isAuthenticated()) {
            dispatch(ordersAsync({pagination, sorting}))
        }
    }, [dispatch, isAuthenticated]);

    const paginationRenderer = () => {
        const {total = 0, pageSize = 20, current= 1} = pagination;
        if(total > pageSize) {
            return <Pagination
                // hideOnSinglePage={true}
                total={total}
                showTotal={(total, range) => `${range[0]}-${range[1]} из ${total}`}
                pageSize={pageSize}
                current={current}
                onChange={(page, pageSize) => {
                    const newPagination = {
                        ...pagination,
                        current: page,
                    }
                    dispatch(ordersAsync({pagination: newPagination, sorting}))
                }}
            />
        } else {
            return null;
        }
    }

    if(isAuthenticated())
        if(!loading) {
            if (orders.length > 0)
                return (
                    <Flex vertical className='orders-page'>
                        <div className='orders'>
                            {orders.map(ord => <Order order={ord} />)}
                        </div>
                        {paginationRenderer()}
                    </Flex>

                )
            else
                return (
                    <Empty
                        description={
                            <Typography.Text>
                                Нет заказов
                            </Typography.Text>
                        }
                    />
                )
        } else {
            return (
                <Skeleton active/>
            )
        }
    else
        return (
            <Empty
                description={
                    <Typography.Text>
                        Нет заказов
                    </Typography.Text>
                }
            />
    )

}

export default OrdersPage;
