import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {searchProducts, searchFactories} from "../../../services/apiService";
import '../../../constants/enums';
import {FactoriesSortTypes, ProductsSortTypes, SearchTypes} from "../../../constants/enums";

export const search1688Slice = createSlice({
    name: 'search',
    initialState: {
        loading: false,
        searchType: SearchTypes.PRODUCTS,
        searchCount: 0,

        productsResult: {
            keywords: "",
            originalKeywords: "",
            products: [],
            pagination: {},
            sort: ProductsSortTypes.DEFAULT
        },
        factoriesResult: {
            keywords: "",
            originalKeywords: "",
            factories: [],
            pagination: {},
            sort: FactoriesSortTypes.DEFAULT
        }
    },
    reducers: {
        setSearchType: (state, {payload}) => {
            state.searchType =payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(searchProductsAsync.pending, (state, action) => {
                const {keywords, sort} = action.meta.arg;
                state.productsResult = {
                    ...state.productsResult,
                    keywords,
                    sort,
                    originalKeywords: undefined
                }
                state.loading = true;
                state.searchCount++;
            })
            .addCase(searchProductsAsync.fulfilled, (state, action) => {
                const {originalKeywords, products, keywords, sort} = action.payload;
                const { content: data = [], pageable: page, totalElements} = products;
                const pagination = {
                    total: totalElements,
                    pageSize: page.pageSize,
                    current: page.pageNumber
                };
                state.productsResult = {
                    ...state.productsResult,
                    originalKeywords,
                    keywords,
                    sort,
                    products: data,
                    pagination
                }
                state.loading = false;
            })
            .addCase(searchProductsAsync.rejected, (state, action) => {
                state.loading = false;
            })
            .addCase(searchFactoriesAsync.pending, (state, action) => {
                const {keywords, sort} = action.meta.arg;
                state.factoriesResult = {
                    ...state.factoriesResult,
                    keywords,
                    sort,
                    originalKeywords: undefined
                }
                state.loading = true;
                state.searchCount++;
            })
            .addCase(searchFactoriesAsync.fulfilled, (state, action) => {
                const {originalKeywords, factories, keywords, sort} = action.payload;
                const { content: data = [], pageable: page, totalElements} = factories;
                const pagination = {
                    total: totalElements,
                    pageSize: page.pageSize,
                    current: page.pageNumber
                };
                state.factoriesResult = {
                    ...state.factoriesResult,
                    originalKeywords,
                    keywords,
                    sort,
                    factories: data,
                    pagination
                }
                state.loading = false;
            })
            .addCase(searchFactoriesAsync.rejected, (state, action) => {
                state.loading = false;
            })
    }
})

export const {setSearchType} = search1688Slice.actions;

export const searchProductsAsync = createAsyncThunk('search/searchProductsAsync',
    ({keywords, originalKeywords, pagination, sort}) => {
    return searchProducts(keywords, originalKeywords, pagination, sort);
})

export const searchFactoriesAsync = createAsyncThunk('search/searchFactoriesAsync',
    ({keywords, originalKeywords, pagination, sort}) => {
    return searchFactories(keywords, originalKeywords, pagination, sort);
})


export default search1688Slice.reducer;
