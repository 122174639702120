import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import '../../../constants/enums';
import {orders} from "../../../services/apiService";

export const ordersSlice = createSlice({
    name: 'orders',
    initialState: {
        loading: false,
        orders: [],
        pagination : {
            showSizeChanger:false,
            total: 0,
            pageSize: 10,
            current: 0
        },
        sorting: {
            field: 'created',
            order: 'descend'
        }
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(ordersAsync.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(ordersAsync.fulfilled, (state, action) => {
                const {content: orders = [], pageable: page, totalElements} = action.payload;
                const pagination = {
                    total: totalElements,
                    pageSize: page.pageSize,
                    current: page.pageNumber + 1
                };
                state.orders = orders;
                state.pagination = pagination;
                state.loading = false;
            })
    }
})

export const {setCurrentDeliveryCompany} = ordersSlice.actions;

export const ordersAsync = createAsyncThunk('orders/ordersAsync', ({pagination, sorting}) => {
    return orders(pagination, sorting);
})

export default ordersSlice.reducer;
