import React from 'react';

import './FInputNumber.less';

export const FInputNumber = ({value = 0, onChange = (value) => {}}) => {

    return(
        <div className="input-group">
            <quantity-input>
                <input type="number" name="quantity" readOnly="" min="1" max="1000" pattern="[0-9]+"
                       inputMode="numeric" value={value}
                       onChange={ (elem) => {
                           onChange(elem.target.value);
                       }}
                    />
                    <div className="qty-wrapper">
                        <div className="quantity-input-plus cursor-pointer"
                             onClick={()=> onChange(value+1)}>
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                 viewBox="0 0 37 64">
                                <path
                                    d="M35.2 28.8q1.92 0 1.92 3.2t-1.92 3.2h-13.44v13.44q0 1.92-3.2 1.92t-3.2-1.92v-13.44h-13.44q-1.92 0-1.92-3.2t1.92-3.2h13.44v-13.44q0-1.92 3.2-1.92t3.2 1.92v13.44h13.44z"></path>
                            </svg>
                        </div>
                        <div className="quantity-input-minus cursor-pointer"
                             onClick={()=> {
                                 if(value > 0)
                                    onChange(value-1)}
                             }
                        >
                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="12" height="12"
                                 viewBox="0 0 37 64">
                                <path
                                    d="M35.2 28.8q1.92 0 1.92 3.2t-1.92 3.2h-33.28q-1.92 0-1.92-3.2t1.92-3.2h33.28z"></path>
                            </svg>
                        </div>
                    </div>
            </quantity-input>
        </div>
    )
}
