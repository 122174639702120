import {
    API_BASE_URL,
    CUSTOMER_CART_BASE_PATH,
    CUSTOMER_DICTIONARIES_PATH,
    CUSTOMER_ORDERS_BASE_PATH,
    PROFILE_BASE_PATH,
    SEARCH_BASE_PATH_COMMON,
    SEARCH_BASE_PATH,
    SEARCH_BASE_PATH_RUS,
    TC_CABINET_BASE_PATH, AUTH_BASE_PATH

} from "../constants";
import axios from 'axios';
import {getToken} from "../auth/useAuth";



const getInstance = (timeout) => {
    let headers = {
        'Content-Type': 'application/json',
    };

    let token = getToken();
    if (token) {
        token = token.replace(/^"(.*)"$/, '$1'); // Remove quotes from token start/end. This is a temp fix?!/
        headers = {
            ...headers,
            Authorization: 'Bearer ' + token
        }
    }

    return axios.create({
        baseURL: API_BASE_URL,
        timeout: timeout,
        headers: headers,
        withCredentials: false
    });
}

const get = (url, options, timeout = 15000) => {
    const instance = getInstance(timeout);
    //console.log(url);
    return instance.get(url, options)
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }
            return response.data;
        })
        .catch(response => {
            return Promise.reject(response);
        });
}


const post = (url, values, timeout = 15000) => {
    const instance = getInstance(timeout);

    return instance.post(url, values).then(response => {
        if (response.status !== 200 && response.status !== 201) {
            return Promise.reject(response);
        }
        return response.data;
    }).catch(response => {
        return Promise.reject(response);
    });
}

const put = (url, values, timeout = 15000) => {
    const instance = getInstance(timeout);

    return instance.put(url, values)
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }
            return response.data;
        })
        .catch(response => {
            return Promise.reject(response);
        });
}

const patch = (url, values, timeout = 15000) => {
    const instance = getInstance(timeout);
    return instance.patch(url, values)
        .then(response => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }
            return response.data;
        })
        .catch(response => {
            return Promise.reject(response);
        });
}

const del = (url, options, timeout = 15000) => {
    const instance = getInstance(timeout);

    return instance.delete(url, options).then(response => {
        if (response.status !== 200) {
            return Promise.reject(response);
        }
        return response.data;
    });
}


const orderDirectionConverter = (order) => {
    switch (order) {
        case 'descend' : return 'desc';
        case 'ascend' : return 'asc';
        default: return '';
    }
}

const fieldsFilterToParams = (fieldsFilter) => {
    const fieldParams = {};
    Object.keys(fieldsFilter).filter(item => fieldsFilter[item]).forEach((item) => {
        fieldParams[item] = fieldsFilter[item].join(',');
    });
    return fieldParams;
}


/********************************************************
 * SEARCH
 *
 */

export const searchProducts = (keywords, originalKeywords, pagination, sort) => {
    let params = {
        keywords,
        originalKeywords,
    };
    if(pagination) {
        params = {
            ...params,
            previousTotal: pagination.total,
            size: pagination.pageSize,
            page: pagination.current,
        }
    }
    if(sort) {
        params = {
            ...params,
            sort
        }
    }
    return get(`${SEARCH_BASE_PATH}/search-products`, {params});
}

export const searchProductsRus = (keywords, pagination, sort) => {
    let params = {
        keywords,
    };
    if(pagination) {
        params = {
            ...params,
            previousTotal: pagination.total,
            size: pagination.pageSize,
            page: pagination.current,
        }
    }
    /* if(sort) {
        params = {
            ...params,
            sort
        }
    } */
    return get(`${SEARCH_BASE_PATH_RUS}/search-products`, {params});
}

export const searchFactories = (keywords, originalKeywords, pagination, sort) => {
    let params = {
        keywords,
        originalKeywords,
    };
    if(pagination) {
        params = {
            ...params,
            previousTotal: pagination.total,
            size: pagination.pageSize,
            page: pagination.current,
        }
    }
    if(sort) {
        params = {
            ...params,
            sort
        }
    }
    return get(`${SEARCH_BASE_PATH}/search-factories`, {params});
}

export const product1688 = (productId) => {
    return get(`${SEARCH_BASE_PATH}/products/${productId}`);
}

export const productDetail1688 = (detailUrl) => {
    let params = {
        path : detailUrl,
        domain : ''
    };
    return get(`${SEARCH_BASE_PATH_COMMON}/loadExtContent`, {params});
}

export const productByUrl1688 = (productUrl) => {
    let params = {
        productUrl
    };
    return get(`${SEARCH_BASE_PATH}/product-by-url`, {params});
}

/********************************************************
 * CUSTOMER
 *
 */

export const getDeliveryCompanies = () => {
    return get(`${CUSTOMER_DICTIONARIES_PATH}/companies`);
}

export const cart = () => {
    return get(`${CUSTOMER_CART_BASE_PATH}`);
}

export const addCartItem = (cartItemSku) => {
    const {productId} = cartItemSku;
    return post(`${CUSTOMER_CART_BASE_PATH}/items/${productId}`, cartItemSku);
}

export const deleteCartItem = (productId) => {
    return del(`${CUSTOMER_CART_BASE_PATH}/items/${productId}`);
}

export const deleteCartItemProp = (productId, skuid) => {
    return del(`${CUSTOMER_CART_BASE_PATH}/items/${productId}/skuid/${skuid}`);
}

export const clearCart = () => {
    return post(`${CUSTOMER_CART_BASE_PATH}/clear`);
}

export const createOrder = (orderValues) => {
    return post(`${CUSTOMER_ORDERS_BASE_PATH}/create-order`, orderValues);
}

export const orders = (pagination, sorting) => {
    const {field = '', order = ''} = sorting;
    let params = {
        size: pagination.pageSize,
        page: pagination.current - 1,
        sort: `${field},${orderDirectionConverter(order)}`
    };

    return get(`${CUSTOMER_ORDERS_BASE_PATH}`, {
        params: params
    });
}

export const saveMyCompany = (companyValues) => {
    return put(`${PROFILE_BASE_PATH}/my-company`, companyValues);
}

export const getMyCompany = () => {
    return get(`${PROFILE_BASE_PATH}/my-company`);
}

export const addCompanyUser = (companyUserValues) => {
    return put(`${PROFILE_BASE_PATH}/users`, companyUserValues);
}

export const getMyCompanyUsers = () => {
    return get(`${PROFILE_BASE_PATH}/users`);
}

export const deleteCustomerCompanyUsers = (email) => {
    return del(`${PROFILE_BASE_PATH}/users/${email}`);
}


/********************************************************
 * RUS SEARCH
 *
 */
export const productRus = (productId) => {
    //console.log(SEARCH_BASE_PATH_RUS)
    return get(`${SEARCH_BASE_PATH_RUS}/products/${productId}`);
}

export const qa = (domain) => {
    let params = {
        domain
    };
    return get(`${SEARCH_BASE_PATH_RUS}/qa`, {params});
}

/********************************************************
 * TC CABINET
 *
 */
export const searchOrders = (pagination, sort) => {
    let params = {};
    if(pagination) {
        params = {
            ...params,
            previousTotal: pagination.total,
            size: pagination.pageSize,
            page: pagination.current,
        }
    }
    if(sort) {
        params = {
            ...params,
            sort
        }
    }
    return get(`${TC_CABINET_BASE_PATH}/orders`, {params});
}

export const order = (orderId) => {
    return get(`${TC_CABINET_BASE_PATH}/orders/${orderId}`);
}

export const updateOrderStatus = (orderId, status) => {
    return patch(`${TC_CABINET_BASE_PATH}/orders/${orderId}`, {
        "processStatus" : status
    });
}

/********************************************************
Авторизация
********************************************************/
export const authenticate = (username, password) => {
    return post(`${AUTH_BASE_PATH}/login`, {
        username,
        password
    });
}

export const registration = (values) => {
    return post(`${AUTH_BASE_PATH}/registration`, values);
}



/********************************************************
 * FACTORIES/SHOPS
 *
 */
export const factory1688 = (memberId) => {
    return get(`${SEARCH_BASE_PATH}/factories/${memberId}`);
}

export const searchShopProducts = (memberId, pagination, sort) => {
    let params = {
        memberId,
    };
    if(pagination) {
        params = {
            ...params,
            previousTotal: pagination.total,
            size: pagination.pageSize,
            page: pagination.current,
        }
    }
    if(sort) {
        params = {
            ...params,
            sort
        }
    }
    return get(`${SEARCH_BASE_PATH}/shop-products`, {params});
}






